@import '~@skyscanner/backpack-web/bpk-mixins/index';
@import '~@skyscanner-internal/bpk-logos/sass';

.App {
  display: grid;
  max-width: 31.25rem;
  margin: auto;

  &__logo {
    margin: bpk-spacing-md() auto bpk-spacing-base() auto;
    @include bpk-apply-primary-color-to(fill);

    > svg {
      display: block;
      margin: auto;
    }
  }

  &__headline {
    margin-bottom: bpk-spacing-md();
    color: $bpk-color-sky-gray-tint-01;
    font-size: $bpk-font-size-lg;
    text-align: center;

    @media screen and (min-width: 43rem) {
      font-size: $bpk-font-size-xl;
    }
  }

  &__image {
    margin-bottom: bpk-spacing-base();
  }

  &__message {
    margin-bottom: bpk-spacing-lg();
    color: $bpk-color-sky-gray-tint-01;
    font-size: $bpk-font-size-base;
    line-height: $bpk-line-height-base;
    text-align: center;
  }

  &__instruction {
    margin-bottom: bpk-spacing-sm();
    color: $bpk-color-sky-gray-tint-01;
    font-size: $bpk-font-size-sm;
    font-weight: bold;
    line-height: $bpk-line-height-sm;
    text-align: center;
  }

  &__captcha {
    margin-bottom: bpk-spacing-base();
    text-align: center;

    /* the next element is created by reCaptcha and has no class we can select it with :( */
    > div > * { /* stylelint-disable-line selector-max-compound-selectors */
      display: block;
      margin: auto;
    }
  }

  &__resolve {
    margin-bottom: bpk-spacing-base();
    color: $bpk-color-sky-gray-tint-02;
    font-size: $bpk-font-size-sm;
    line-height: $bpk-line-height-sm;
    text-align: center;

    b {
      font-weight: bold;
    }
  }

  &__identifier {
    margin-bottom: bpk-spacing-base();
    color: $bpk-color-sky-gray-tint-03;
    font-size: $bpk-font-size-sm;
    line-height: $bpk-line-height-sm;
    text-align: center;
  }

  &__error {
    margin: 2em 0 0;
  }

  @supports (grid-template-rows: auto) {
    grid-template-rows:
      bpk-spacing-md() auto /* logo */
      bpk-spacing-base() auto /* headline */
      bpk-spacing-md() auto /* image */
      bpk-spacing-base() auto /* message */
      bpk-spacing-lg() auto /* instruction */
      bpk-spacing-sm() auto /* captcha */
      bpk-spacing-base() auto /* resolve */
      bpk-spacing-base() auto /* identifier */
      bpk-spacing-base();
    grid-template-columns: bpk-spacing-base() auto 15rem auto bpk-spacing-base();

    @media screen and (max-width: 22.5rem) {
      grid-template-columns: 0 auto 15rem auto 0;
    }

    @media screen and (min-width: 43rem) {
      grid-template-rows:
        bpk-spacing-base() auto /* logo */
        bpk-spacing-lg() auto /* image */
        bpk-spacing-sm() auto /* headline */
        bpk-spacing-sm() auto /* message */
        bpk-spacing-lg() auto /* instruction */
        bpk-spacing-sm() auto /* captcha */
        bpk-spacing-base() auto /* resolve */
        bpk-spacing-base() auto /* identifier */
        bpk-spacing-base();
    }

    &__logo {
      margin: 0;
      grid-column: 3 / 4;
      grid-row: 2 / 3;
    }

    &__headline {
      margin: 0;
      grid-column: 2 / 5;
      grid-row: 4 / 5;

      @media screen and (min-width: 43rem) {
        grid-row: 6 / 7;
      }
    }

    &__image {
      margin: 0;
      grid-column: 2 / 5;
      grid-row: 6 / 7;

      @media screen and (min-width: 43rem) {
        grid-row: 4 / 5;
      }
    }

    &__message {
      margin: 0;
      grid-column: 2 / 5;
      grid-row: 8 / 9;
    }

    &__instruction {
      margin: 0;
      grid-column: 2 / 5;
      grid-row: 10 / 11;
    }

    &__captcha {
      margin: 0;
      grid-column: 2 / 5;
      grid-row: 12 / 13;
    }

    &__resolve {
      margin: 0;
      grid-column: 2 / 5;
      grid-row: 14 / 15;
    }

    &__identifier {
      margin: 0;
      grid-column: 2 / 5;
      grid-row: 16 / 17;
    }
  }
}
